import React from "react";
import { Link } from "gatsby"


const archivelLink = () => {
  const years = ["2021","2022","2023","2024"]
  return (
    <div className="archive-year-link">
      <Link  to="/blog">all</Link>
      {years.map(year => {
        const slug = "/archives/" + year
        return (
          <Link to={slug}>{year}</Link>
        )
      })}
    </div>
  )
}

export default archivelLink