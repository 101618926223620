import * as React from "react"
import { Link } from "gatsby"
import Header from "./header"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  header = (
    <header className="main-heading">
      <h1>
        <Link to="/">{title}</Link>
      </h1>
    </header>
  )

  return (
    <div data-is-root-path={isRootPath} id="outer-container">
      <Header location={location} />
      {header}
      <main className="global-main">{children}</main>
      <footer className="global-footer">
        © {new Date().getFullYear()} {title}
      </footer>
    </div>
  )
}

export default Layout
